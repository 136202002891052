// nyweb3 local.less
// jan. 2025: test av studieprogramsider for OsloMet

// X
@import "../../../../../../../lib/x1/css/x-0.less";



// EN use same logo as NO
html[lang=en] #header {
  .logo {
    background-image: url("../images/logo.svg");
  }
}

// RSS feed link
#vrtx-feed-link,
.vrtx-feed-link {
  display: none;
}

/************************************************
Study program pages
*************************************************/

// Program list page
#vrtx-fs-studieprogram-listing {
  #global-sub-menu {
    display: none;
  }
}
#vrtx-listing-filter-hits {
  margin-bottom: 30px;
}
#vrtx-fs-studieprogram-listing-results {
  h3 {
    font-weight: normal;
    margin-bottom: 25px;
  }
  a {
    .mixin-arrow-link;
    font-weight: normal;
  }
}

// Facts. On frontpage, plan and subject
#vrtx-fs-studieprogram-facts,
#vrtx-fs-emne-facts {
  margin: 60px 0;
  h2 {
    .invisible; // Redundant. Hidden except for screen-reader.
  }
  h4 {
    display: none; // Removed. Wrong in hierarchy.
  }
  dl {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 15px 60px;
    .dg {
      border-top: 1px solid #0003;
      padding-top: 10px;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
  }
}

// Frontpage
#vrtx-fs-studieprogram-frontpage {
  #vrtx-fs-studieprogram-search-button {
    margin-bottom: 60px;
    a {
      .button-loud;
    }
  }
  #vrtx-main-content {
    h2:not(.accordion) {
      margin: 50px 0 20px;
    }
  }
  .vrtx-article-body {
    border: none;
    padding: 0;
    margin-bottom: 50px;
  }
  #vrtx-fs-studieprogram-plans {
    ul {
      list-style: none;
      padding: 0;
      a {
        .mixin-h3;
      }
    }
  }
}

// Text/link above heading on plan page
.vrtx-context-box {
  margin-bottom: 20px;
}

// Study plan and subject page. Common elements
// NOTE: Subject page will need tpl override
#vrtx-fs-studieprogram-document,
#vrtx-fs-emne {
  .facts-wrapper {
    margin-bottom: 60px;
  }
  .toc-and-content {
    margin-top: 40px;
    display: grid;
    gap: 60px;
    @media (@screen-large) {
      grid-template-columns: 1fr 1fr 1fr; // Triple to align with facts layout
      align-items: start;
    }
  }
  #toc-wrapper {
    background-color: var(--color-neutral-tint-95);
    padding: 25px;
    @media (@screen-large) {
      position: sticky;
      top: 10px;
      max-height: calc(100vh - 20px);
      overflow: auto;
    }
    h2 {
      font-size: inherit;
      font-weight: bold;
      margin-bottom: 10px;
    }
    #toc {
      margin: 0;
    }
    ul {
      list-style: none;
      a {
        position: relative;
        display: block;
        font-weight: normal;
        text-decoration: none;
        &::before {
          content: "";
          position: absolute;
          background: url("../images/arrow.svg") no-repeat center;
          background-size: contain;
          width: 18px;
          height: 1.5em;
          left: -30px;
        }
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  #content-wrapper {
    max-width: var(--width-text-max);
    @media (@screen-large) {
      grid-column: span 2;
    }
    h2 {
      margin: 50px 0 20px 0; // Match vrtx-article-body, which is part of this
    }
    > h2:first-child {
      margin-top: 0;
    }
  }
}

// Study plan model
.vrtx-fs-study-model {
  .course-list {
    list-style: none;
    padding: 0;
    margin-bottom: 40px;
    li {
      border: 1px solid #0003;
      border-width: 1px 0 0 0;
      &:last-child {
        border-width: 1px 0;
      }
      > * {
        display: grid;
        grid-template-columns: 1fr auto;
        gap: 40px; // Must be enough for arrow
      }
      a {
        text-decoration: none;
        // Course name when linked
        .course-name {
          font-weight: bold;
          &::after {
            content: "";
            background: url("../images/arrow.svg") no-repeat center;
            background-size: contain;
            padding-right: 24px;
            margin-left: 10px;
          }
        }
        &:hover {
          .course-name {
            text-decoration: underline;
          }
        }
      }
      .course-description {
        padding: 10px 0 15px;
      }
      .course-code {
        display: block;
        font-size: var(--font-size-tiny);
      }
      .course-not-linked-info {
        display: none; // Hide info about there being no link, since there is no link :)
      }
      .course-study-points {
        align-content: center;
        text-align: center;
        background-color: var(--color-neutral-tint-95);
        padding: 0 5px;
        min-width: 70px;
        line-height: 1.1;
        span {
          // Number
          &:first-child {
            display: block;
            font-weight: bold;
            margin-top: 4px; // Tweak vertical pos
          }
          &:last-child {
            font-size: var(--font-size-tiny);
          }
        }
      }
      // Non-mandatory / optional courses. Not sure if there are any.
      &:not(.mandatory) {
        .course-study-points span:first-child {
          &::before {
            content: "(";
          }
          &::after {
            content: ")";
          }
        }
      }
    }
  }
}


